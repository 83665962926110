import { Image, ImageWithPrintUrl } from "@mcp-artwork/cimdoc-types-v2";
import { ValidationResult } from "../document/Validation";
import { DecorationTechnology } from "../helpers/Technology";

export function imageValidation({ image, decoTech }: { image: Image; decoTech: DecorationTechnology }): ValidationResult {
  // Always allow temp urls like blob:...
  if (image.previewUrl && !image.previewUrl.startsWith("http")) {
    return { status: "pass" };
  }

  // Fallback only for embroidery when printUrl has a value
  if (decoTech === "embroidery" && (image as ImageWithPrintUrl).printUrl) {
    return { status: "fail", error: "Embroidery is not supported!" };
  }

  if (image.specialProcessing) {
    // TODO: Actually implement legacyFinishMask
    if (image.specialProcessing.find((sp) => sp !== "legacyFinishMask")) {
      return { status: "fail", error: "Special processing is not supported!" };
    }
  }

  if (image.colorOverrides) {
    return { status: "fail", error: "Color overrides on images are not supported" };
  }

  if (image.effects && image.effects.length > 0) {
    for (const effect of image.effects) {
      if (effect.type !== "colorMatrix") {
        return { status: "fail", error: "Image effects on images are not supported" };
      }
    }
  }

  return { status: "pass" };
}
