import { TextMeasurements } from "../../layout/text/TextMeasurements";
import { xRenderMetaData } from "../../utils/api/image";
import { BoundingBox } from "../../utils/boundingBox";
import { parseMM } from "../../utils/unitHelper";

export type FallbackPreviewMetadata = {
  previewBox: BoundingBox;
  pathInclusiveBox?: BoundingBox;
  actual?: {
    width: number;
    height: number;
  };
};

export function parsePreviewMetadata(xRenderMetadataHeader: xRenderMetaData): FallbackPreviewMetadata {
  const serverPreviewBox = {
    left: parseMM(xRenderMetadataHeader.previewBox.left),
    height: parseMM(xRenderMetadataHeader.previewBox.height),
    top: parseMM(xRenderMetadataHeader.previewBox.top),
    width: parseMM(xRenderMetadataHeader.previewBox.width),
  };

  let actual, pathInclusiveBox;

  if (xRenderMetadataHeader.actual !== undefined) {
    actual = {
      width: parseMM(xRenderMetadataHeader.actual.width),
      height: parseMM(xRenderMetadataHeader.actual.height),
    };
  }

  if (xRenderMetadataHeader.pathInclusiveBox !== undefined) {
    pathInclusiveBox = {
      left: parseMM(xRenderMetadataHeader.pathInclusiveBox.left),
      height: parseMM(xRenderMetadataHeader.pathInclusiveBox.height),
      top: parseMM(xRenderMetadataHeader.pathInclusiveBox.top),
      width: parseMM(xRenderMetadataHeader.pathInclusiveBox.width),
    };
  }

  const previewMetadata: FallbackPreviewMetadata = {
    previewBox: serverPreviewBox,
    pathInclusiveBox,
    actual,
  };

  return previewMetadata;
}

export function parseTextMeasurements(xRenderMetadataHeader: xRenderMetaData): TextMeasurements | undefined {
  // Normal text always has non-empty baselines, actual, snapBox, and may have resizeFactor, but text along a path will always have null baselines
  // and potentially a resizeFactor. No other cimdoc item besides text has a resizeFactor, so only text will pass this condition.
  if (xRenderMetadataHeader.baselines || xRenderMetadataHeader.resizeFactor) {
    const textMeasurements: TextMeasurements = {};

    if (xRenderMetadataHeader.actual) {
      textMeasurements.actual = {
        width: parseMM(xRenderMetadataHeader.actual.width),
        height: parseMM(xRenderMetadataHeader.actual.height),
      };
    }

    if (xRenderMetadataHeader.snapBox) {
      textMeasurements.snapBox = {
        left: parseMM(xRenderMetadataHeader.snapBox.left),
        top: parseMM(xRenderMetadataHeader.snapBox.top),
        width: parseMM(xRenderMetadataHeader.snapBox.width),
        height: parseMM(xRenderMetadataHeader.snapBox.height),
      };
    }

    textMeasurements.baselines = xRenderMetadataHeader.baselines?.map((b) => parseMM(b));
    textMeasurements.resizeFactor = xRenderMetadataHeader.resizeFactor;

    return textMeasurements;
  }
}
