import { ColorPalette, Stroke, TextArea, TextField } from "@mcp-artwork/cimdoc-types-v2";
import {
  TextEngine,
  FontFlavor,
  CalculationRequestCollection,
  CalculationResultCollection,
  CalculationResult,
  TextBlock,
  ResultFont,
  GlyphRun,
  Rectangle,
} from "@mcp-artwork/rtext";
import { parseMM } from "../../utils/unitHelper";
import { buildInteractiveRequest, interpretTextArea } from "./RTextTranslation";
import { BoundingBox, RotatedBoundingBox, boundingBoxToPreviewBox, computeTextAreaBounds, expandBoundingBox, maximum } from "../../utils/boundingBox";
import { TextCalculationResultCollection } from "@mcp-artwork/rtext/lib-mjs/core/pkg/rtext";
import { computeExtraBounds, getCaretLines, getMeasurements, getTextBoundaries, TextMeasurements } from "./TextMeasurements";
import { LayoutElement, PreviewType, TextOptions } from "../../models/Layout";
import { buildTransform } from "../helpers/Transform";
import { getClip } from "../helpers/Clip";
import { timestamp } from "../../utils/time";
import { log } from "../../utils/log";
import { initRText } from "./initRText";
import { buildRenderingOperations } from "./renderingOperations";
import { applyPathToCarets, applyPathToGlyphBounds, applyPathToText, getTransformedPiecewisePath } from "./textOnPath/textPathProcessor";
import { PiecewisePath } from "./textOnPath/piecewisePath";
import { ClipPath, PathOperation } from "../Models";
import { Matrix } from "../../utils/math/matrix";
import { TextPathData } from "../../utils/text/textUtil";
import CimDocDefinitionTreeNode from "../../utils/CimDocDefinitionTreeNode";
import cloneDeep from "lodash.clonedeep";
import { getMeasurementData } from "../measurements/measurementData";

// This is the equivalent to the server side code Length.FromDtpPoints(1.25)
const PREVIEW_BOX_INFLATION = 1.25 * 0.352778;

export async function textAreaLayout({
  textArea,
  textOptions,
  decorationTechnology,
  fontRepositoryUrl,
  parentBounds,
  trackTime,
  enableLog,
  options,
  previewType,
}: {
  textArea: TextArea;
  textOptions?: TextOptions;
  decorationTechnology: string;
  fontRepositoryUrl: string;
  parentBounds: BoundingBox;
  trackTime: boolean;
  enableLog: boolean;
  options: { definitionTreeNode?: CimDocDefinitionTreeNode; colorPalette?: ColorPalette };
  previewType: PreviewType;
}): Promise<LayoutElement> {
  // Needs to be cloned deep because the properties may be modified
  textArea = cloneDeep(textArea);
  const fontFlavor: FontFlavor = decorationTechnology.includes("embroidery") ? "embroidery" : "print";

  if (fontFlavor === "embroidery") {
    throw Error("Embroidered text cannot be rendered client-side!");
  }

  log({ message: "calling given initRText function", enabled: enableLog && !!textOptions?.initRText });
  const textEngine: TextEngine = textOptions?.initRText ? await textOptions.initRText() : await initRText(enableLog);

  const startTranslation = timestamp(trackTime);

  // Applying all the functionality around list handling
  const { listOrdinals, textFields, textBlocks, isNewParagraphTextBlock } = interpretTextArea(textArea, fontFlavor)
    // Remove the bew line characters in case of text along a path, and then remove blocks if empty.
    .reduce<{ listOrdinals: boolean[]; textFields: (TextField & { type?: "inline" | "list" })[]; textBlocks: TextBlock[]; isNewParagraphTextBlock: boolean[] }>(
      (outputItc, itc) => {
        outputItc.isNewParagraphTextBlock.push(itc.isNewParagraphTextBlock);
        outputItc.listOrdinals.push(itc.listOrdinal);
        outputItc.textBlocks.push(itc.textBlock);
        outputItc.textFields.push(itc.textField);
        return outputItc;
      },
      { listOrdinals: [], textFields: [], textBlocks: [], isNewParagraphTextBlock: [] },
    );

  let pieceWiseTransformedPath: PiecewisePath | undefined;
  if (textArea.textPath !== undefined) {
    pieceWiseTransformedPath = getTransformedPiecewisePath(textArea);
  }
  const request: CalculationRequestCollection = buildInteractiveRequest(
    textArea,
    pieceWiseTransformedPath,
    textBlocks,
    fontFlavor,
    fontRepositoryUrl,
    textOptions,
  );
  const endTranslation = timestamp(trackTime);
  const startRtext = timestamp(trackTime);

  log({ message: `calling rtext ${textArea.id}`, enabled: enableLog, objects: { request } });
  const engineResult: TextCalculationResultCollection = await textEngine.process(request);

  try {
    const resultCollection = engineResult.to_obj() as CalculationResultCollection;
    log({ message: `rtext result ${textArea.id}`, enabled: enableLog, objects: { result: resultCollection } });

    if (!resultCollection.results || resultCollection.results.length !== 1) {
      throw Error("Incorrect result from rtext");
    }

    const resultFonts: ResultFont[] | undefined = resultCollection.fonts;
    const result: CalculationResult = resultCollection.results[0];

    const textAreaX = parseMM(textArea.position.x);
    const textAreaY = parseMM(textArea.position.y);
    let textPathData: TextPathData | undefined = undefined;

    if (pieceWiseTransformedPath !== undefined) {
      const glyphRunOutput = applyPathToText(textArea, textBlocks, pieceWiseTransformedPath, result);
      textPathData = {
        glyphRuns: glyphRunOutput.glyphRuns,
        piecewisePath: pieceWiseTransformedPath,
        caretLines: textOptions?.caretLines ? applyPathToCarets(textArea, pieceWiseTransformedPath, result, glyphRunOutput.visibleGlyphs) : undefined,
      };
    }

    let itemPreviewTransform = Matrix.identity();
    const originalScaleTransform = textArea.scale;
    const boundingBox = computeTextAreaBounds({ textArea, engineResult: result });

    itemPreviewTransform = Matrix.multiply(
      itemPreviewTransform,
      buildTransform({
        bounds: boundingBox,
        skew: textArea.skew,
        scale: textArea.scale,
        rotationAngle: textArea.rotationAngle,
        matrixTransform: textArea.transform,
        itemTransforms: textArea.transforms,
      }),
    );

    if (previewType === "item") {
      // This has to be set to undefined since in renderingOperations the scale, rotationAngle, and transform could be premultiplied in certain cases
      textArea.scale = undefined;
      textArea.rotationAngle = undefined;
      textArea.transform = undefined;
      textArea.transforms = undefined;
    }

    const endRtext = timestamp(trackTime);
    const startMeasurements = timestamp(trackTime);

    let textMeasurements: TextMeasurements;
    let previewBox: BoundingBox;
    let backgroundPreviewBox: BoundingBox | undefined;
    let pathInclusiveBox: RotatedBoundingBox | undefined;
    let operations: PathOperation[];
    let transform: Matrix;
    let clipTransform: Matrix;
    let filterBounds: BoundingBox[] = [];
    let endMeasurements: number | undefined;
    let instructionStart: number | undefined;

    if (textArea.textPath === undefined) {
      instructionStart = timestamp(trackTime);
      ({ operations, transform, filterBounds, clipTransform, backgroundPreviewBox } = await buildRenderingOperations({
        textArea,
        textFields,
        result,
        fonts: resultCollection.fonts ?? [],
        parentBounds,
        options,
      }));

      textMeasurements = getMeasurements({
        textFields,
        textBlocks,
        listOrdinals,
        isNewParagraphTextBlock,
        textArea,
        result,
        fonts: resultCollection.fonts ?? [],
        textOptions,
        textOrientation: request.requests[0].textArea?.textOrientation ?? "horizontal",
        textPathData,
      });

      const blackBoxBounds = result.blackBoxBounds as Rectangle;
      if (backgroundPreviewBox) {
        previewBox = backgroundPreviewBox;
      } else {
        previewBox = { left: blackBoxBounds.x + textAreaX, top: blackBoxBounds.y + textAreaY, width: blackBoxBounds.width, height: blackBoxBounds.height };
      }

      log({ message: `text measurements ${textArea.id}`, enabled: enableLog, objects: { textMeasurements } });
      endMeasurements = timestamp(trackTime);
      instructionStart = timestamp(trackTime);
    } else {
      endMeasurements = timestamp(trackTime);
      instructionStart = timestamp(trackTime);

      ({ operations, transform, filterBounds, clipTransform } = await buildRenderingOperations({
        textArea,
        textFields,
        result,
        fonts: resultCollection.fonts ?? [],
        parentBounds,
        textPathData,
        options,
      }));

      if (!result.glyphRuns || !result.glyphRuns.length) {
        previewBox = { left: 0, top: 0, width: 0, height: 0 };
      } else {
        previewBox = getTextPathBoundingBox({
          textArea: textArea,
          path: pieceWiseTransformedPath as PiecewisePath,
          resultFonts: resultFonts as ResultFont[],
          glyphRuns: result.glyphRuns as GlyphRun[],
        });
      }

      previewBox = maximum([previewBox, ...filterBounds]);

      textMeasurements = {
        actual: { width: boundingBox.width, height: boundingBox.height },
        baselines: [],
        snapBox: previewBox,
        resizeFactor: result.resizeFactor,
        caretLines:
          textOptions?.caretLines && textPathData && textPathData.caretLines
            ? getCaretLines(textPathData.caretLines, textBlocks, listOrdinals, isNewParagraphTextBlock, textArea.blockFlowDirection)
            : undefined,
        textBoundaries: textOptions?.requestTextBoundaries ? getTextBoundaries(result.textBoundaries ?? [], textBlocks, listOrdinals) : undefined,
      };
    }

    let actualTransform = transform;
    let decorationBounds: BoundingBox[] = [];

    if (textArea.textPath === undefined) {
      decorationBounds = computeExtraBounds({ textFields, result, fonts: resultCollection.fonts ?? [], textAreaPosition: textArea.position });
    }

    const measurementDataResponse = getMeasurementData({
      boundingBox: boundingBox,
      scaleTransform: originalScaleTransform,
      tightBounds: previewBox,
      transform: itemPreviewTransform,
      stroke: getMaxStroke(textFields),
      extraBounds: [...filterBounds, ...decorationBounds],
      itemType: "textArea",
    });

    if (pieceWiseTransformedPath !== undefined) {
      const pathInclusiveBounds = pieceWiseTransformedPath.getPathInclusiveBounds(resultCollection, textArea.position);

      const pathInclusiveBoundsMeasurements = getMeasurementData({
        boundingBox: boundingBox,
        tightBounds: pathInclusiveBounds,
        scaleTransform: originalScaleTransform,
        transform: itemPreviewTransform,
        itemType: "textArea",
      });

      pathInclusiveBox = pathInclusiveBoundsMeasurements.measurementData.previewBox;

      // Snap box of text along a path should be the path inclusive box, minus the position (so that it's consistent with normal text snap box)
      textMeasurements.snapBox = { ...pathInclusiveBox };
      textMeasurements.snapBox.left -= textAreaX;
      textMeasurements.snapBox.top -= textAreaY;
    }

    if (previewType === "item") {
      actualTransform = measurementDataResponse.itemPreviewTransform;

      operations.forEach((op) => {
        op.transform = Matrix.multiply(op.transform, actualTransform);
      });
    }

    const clipPath: ClipPath | undefined = await getClip(textArea, parentBounds, clipTransform, fontRepositoryUrl);

    const instructionEnd = timestamp(trackTime);

    const layoutElement: LayoutElement = {
      id: textArea.id,
      status: { mode: "local" },
      measurementData: {
        boundingBox: clipPath?.boundingBox ?? measurementDataResponse.measurementData.boundingBox,
        previewBox:
          clipPath?.boundingBox ??
          expandBoundingBox({
            boundingBox: measurementDataResponse.measurementData.previewBox,
            amount: PREVIEW_BOX_INFLATION,
          }),
        layoutBox:
          clipPath?.boundingBox ??
          expandBoundingBox({
            boundingBox: measurementDataResponse.measurementData.layoutBox,
            amount: PREVIEW_BOX_INFLATION,
          }),
        pathInclusiveBox,
        textMeasurements,
      },
      renderingOperation: {
        type: "drawPaths",
        paths: operations,
        clip: clipPath,
        opacityMultiplier: textArea.opacityMultiplier ?? 1,
      },
      ...(trackTime &&
      endTranslation &&
      startTranslation &&
      endRtext &&
      startRtext &&
      instructionEnd &&
      instructionStart &&
      endMeasurements &&
      startMeasurements
        ? {
            debugInfo: {
              timers: {
                translation: endTranslation - startTranslation,
                rtext: endRtext - startRtext,
                instructions: instructionEnd - instructionStart,
                measurements: endMeasurements - startMeasurements,
              },
            },
          }
        : {}),
    };

    return layoutElement;
  } finally {
    engineResult.free();
  }
}

export function getTextPathBoundingBox({
  textArea,
  path,
  resultFonts,
  glyphRuns,
}: {
  textArea: TextArea;
  path: PiecewisePath;
  resultFonts: ResultFont[];
  glyphRuns: GlyphRun[];
}): BoundingBox {
  const glyphBounds: BoundingBox[] = applyPathToGlyphBounds(textArea, path, resultFonts, glyphRuns);

  if (glyphBounds.length === 0) {
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    };
  }

  const boundingBox = maximum(glyphBounds);
  boundingBox.left += parseMM(textArea.position.x);
  boundingBox.top += parseMM(textArea.position.y);

  return boundingBox;
}

function getMaxStroke(textFields: TextField[]): Stroke | undefined {
  let maxStrokeThickness = 0;
  let maxStroke: Stroke | undefined;
  let hasContent = false;

  if (textFields && textFields.length > 0) {
    textFields.forEach((field) => {
      if (field.content.length > 0) {
        hasContent = true;
      }

      if (field.stroke?.thickness !== undefined && parseMM(field.stroke.thickness) > maxStrokeThickness) {
        maxStrokeThickness = parseMM(field.stroke.thickness);
        maxStroke = field.stroke;
      }
    });
  }

  return hasContent ? maxStroke : undefined;
}
