import { Image, ItemClip, ItemReference, Shape, Subpanel, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { OrderableItemInfo, PreviewType } from "../../models/Layout";
import { FallBackItemOptions } from "../Item";
import { Matrix } from "../../utils/math/matrix";
import { buildItemTransform, buildTransform } from "../../layout/helpers/Transform";

export type GetItemForPanelReturn = {
  originalClipping?: ItemClip;
  itemToAdd: TextAreaReturn | ItemReferenceReturn | ImageReturn | SubpanelReturn | ShapeReturn;
  scaleFactor: number;
};

type TextAreaReturn = {
  textAreas: [TextArea];
};

type ItemReferenceReturn = {
  itemReferences: [ItemReference];
};

type ImageReturn = {
  images: [Image];
};

type SubpanelReturn = {
  subpanels: [Subpanel];
};

type ShapeReturn = {
  shapes: [Shape];
};

// Set position of items to 0,0 and rotationAngle to 0 for sending to the server for a preview
export async function getItemForPanel({ itemInfo }: { itemInfo: OrderableItemInfo; previewType: PreviewType }): Promise<GetItemForPanelReturn> {
  if (itemInfo.itemType === "ornament" || itemInfo.itemType === "video") {
    throw Error(`Fallback is currently not supported on ornaments and videos.`);
  }
  const item = itemInfo.item;

  // This is a very rough estimate to determine how much the fallback image will be scaled. It is not meant to be a perfect approximation.
  let scaleFactor = 1;

  const transform = buildTransform({
    bounds: { left: 0, top: 0, width: 0, height: 0 }, // doesn't matter
    scale: item.scale,
    matrixTransform: item.transform,
    itemTransforms: item.transforms,
  });

  scaleFactor = Math.max(Math.abs(transform.a), Math.abs(transform.b), Math.abs(transform.c), Math.abs(transform.d));

  if (itemInfo.itemType === "textArea") {
    const textArea = item as TextArea;

    return {
      originalClipping: textArea.clipping,
      scaleFactor,
      itemToAdd: {
        textAreas: [
          {
            ...textArea,
            position: {
              ...textArea.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            clipping: undefined,
            transforms: undefined,
            transform: undefined,
          },
        ],
      },
    };
  } else if (itemInfo.itemType === "itemReference") {
    const itemRef = item as ItemReference;

    return {
      scaleFactor,
      itemToAdd: {
        itemReferences: [
          {
            ...itemRef,
            position: {
              ...itemRef.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            rotationAngle: undefined,
            transforms: undefined,
            transform: undefined,
          },
        ],
      },
    };
  } else if (itemInfo.itemType === "subpanel") {
    const subpanel = item as Subpanel;

    return {
      scaleFactor,
      itemToAdd: {
        subpanels: [
          {
            ...subpanel,
            position: {
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            transforms: undefined,
            transform: undefined,
          },
        ],
      },
    };
  } else if (itemInfo.itemType === "image") {
    const image: Image = item as Image;

    return {
      originalClipping: image.clipping,
      scaleFactor,
      itemToAdd: {
        images: [
          {
            ...image,
            position: {
              ...image.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            clipping: undefined,
            transforms: undefined,
            transform: undefined,
          },
        ],
      },
    };
  }
  // shapes
  else {
    const shape = item as Shape;

    if (shape.type !== "line") {
      return {
        originalPosition: shape.position,
        scaleFactor,
        itemToAdd: {
          shapes: [
            {
              ...shape,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              position: { ...shape.position, x: "0mm", y: "0mm" },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              rotationAngle: undefined,
              scale: undefined,
              skew: undefined,
              transforms: undefined,
              transform: undefined,
            },
          ],
        },
      };
    }

    // line item
    return {
      scaleFactor,
      itemToAdd: {
        shapes: [
          {
            ...shape,
            scale: undefined,
            rotationAngle: undefined,
            skew: undefined,
            transforms: undefined,
            transform: undefined,
          },
        ],
      },
    };
  }
}
