import { useState } from "react";
import { CimpressDocument } from "@mcp-artwork/cimdoc-types-v2";
import CSS from "./DiffBlock.module.css";
import { LayoutElement, LayoutInput, parseMM } from "@rendering/plasma";
import { useDocument } from "../../hooks/useDocument";
import { DocumentEditor, JSONReadOnlyViewer } from "../../JSONViewer";
import { getPrimarySurface, selectAllItemIds, selectFirstItemFromDocument, selectFirstSurface, selectItemWithId } from "../../selectors/document";
import { Item, ItemType } from "../../Models/Item";
import { FusionCanvas } from "../../FusionCanvas";
import { DiffCanvas } from "./DiffCanvas";
import { UseLayoutFusionInput, UseLayoutState } from "../../hooks/useLayout";
import { PaintResult } from "@rendering/neon";
import { findOverprintsInDocument } from "../../utils/overprint";

type DiffBlockProps = {
  url: string;
};

const defaultPixel = 0.15;

const getPixelFromURL = (url: string): number | undefined => {
  const pixelSize = new URLSearchParams(new URL(url).search).get("pixel");

  if (pixelSize) {
    return parseMM(pixelSize);
  }
  return undefined;
};

const formatMeasurementData = (element: LayoutElement | undefined): string => {
  if (element === undefined) return "";

  if (element.measurementData.textMeasurements) {
    return JSON.stringify(
      {
        actual: element?.measurementData.textMeasurements?.actual,
        snapBox: element?.measurementData.textMeasurements?.snapBox,
        baselines: element?.measurementData.textMeasurements?.baselines,
      },
      null,
      4,
    );
  }

  return JSON.stringify(
    {
      previewBox: element.measurementData.previewBox,
    },
    null,
    4,
  );
};

export function DiffBlock({ url }: DiffBlockProps) {
  const [images, setImages] = useState<{ clientSide?: ImageData; serverSide?: ImageData }>({ clientSide: undefined, serverSide: undefined });
  const [showMore, setShowMore] = useState<boolean>(false);
  const [pixelSize, setPixelSize] = useState<number>((getPixelFromURL(url) || defaultPixel) / Math.ceil(window.devicePixelRatio));
  const { document, error, setDocument } = useDocument({ url });
  const [selectedItem, setItem] = useState<{ item?: Item; itemType?: ItemType }>({});
  const { item } = selectedItem.item ? selectedItem : document ? selectFirstItemFromDocument({ document }) : selectedItem;
  const [clientSideLayout, setClientSideLayout] = useState<UseLayoutState>();
  const [serverSideLayout, setServerSideLayout] = useState<UseLayoutState>();
  const [diffPixels, setDiffPixels] = useState<number>();
  const [renderFullDocument, setRenderFullDocument] = useState<boolean>(false);

  const onDocumentChange = (document: CimpressDocument): void => {
    const cloneDocument = JSON.parse(JSON.stringify(document));
    setDocument(cloneDocument);
  };

  const onSelectItem = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (document) {
      const surface = getPrimarySurface({ document });
      const selected = selectItemWithId({ surface, id: event.target.value });
      setItem(selected);
    }
  };

  const onClientSideLayout = (layout: UseLayoutState) => {
    setImages((prev) => ({ serverSide: prev.serverSide, clientSide: undefined }));
    setClientSideLayout(layout);
  };

  const onServerSideLayout = (layout: UseLayoutState) => {
    setImages((prev) => ({ serverSide: undefined, clientSide: prev.clientSide }));
    setServerSideLayout(layout);
  };

  const onClientSidePaint = ({ canvas }: { paintResults: PaintResult[]; canvas: HTMLCanvasElement }) =>
    setImages((prev) => ({
      clientSide: (canvas.getContext("2d") as CanvasRenderingContext2D).getImageData(0, 0, canvas.width, canvas.height),
      serverSide: prev.serverSide,
    }));

  const onServerSidePaint = ({ canvas }: { paintResults: PaintResult[]; canvas: HTMLCanvasElement }) =>
    setImages((prev) => ({
      serverSide: (canvas.getContext("2d") as CanvasRenderingContext2D).getImageData(0, 0, canvas.width, canvas.height),
      clientSide: prev.clientSide,
    }));

  if (error) {
    return (
      <div className={CSS.Container}>
        <pre className={CSS.Error}>{error.message || JSON.stringify(error)}</pre>
      </div>
    );
  }

  const input: UseLayoutFusionInput | undefined =
    document &&
    item &&
    (() => {
      if (renderFullDocument) {
        return {
          pixelSize: `${pixelSize}mm`,
          document,
          overprints: findOverprintsInDocument(document),
          selector: { id: selectFirstSurface(document).id, type: "panel" },
          debugOptions: {
            timers: true,
          },
          referrer: "fusion-demo",
        };
      }
      return {
        referrer: "fusion-demo",
        pixelSize: `${pixelSize}mm`,
        document,
        overprints: findOverprintsInDocument(document),
        selector: { id: item.id, type: "item" },
        debugOptions: { timers: true },
      };
    })();

  return (
    <div className={CSS.Container}>
      <label className={CSS.OptionLabel}>
        MM per Pixel&nbsp;
        <input
          type="number"
          value={pixelSize}
          onInput={(e) => {
            const float = parseFloat(e.currentTarget.value);
            if (float > 0) {
              setPixelSize(float);
            }
          }}
          step={0.1}
        />
      </label>
      <label className={CSS.OptionLabel}>
        Render full document&nbsp;
        <input
          type="checkbox"
          checked={renderFullDocument}
          onChange={() => {
            setRenderFullDocument(!renderFullDocument);
          }}
        />
      </label>
      <button
        onClick={(e) => {
          const containerWidth: number | undefined = e.currentTarget.parentElement?.offsetWidth;
          if (clientSideLayout?.layoutResult && containerWidth) {
            let width: number;
            if (renderFullDocument) {
              width = clientSideLayout.layoutResult.boundingBox.width;
            } else {
              width = clientSideLayout.layoutResult.elements[0].measurementData.boundingBox.width;
            }

            const pixelSize = width / (containerWidth / 3);
            setPixelSize(parseFloat((pixelSize / Math.ceil(window.devicePixelRatio)).toFixed(2)));
          }
        }}
      >
        Fit width to screen
      </button>
      {document && !renderFullDocument && (
        <label className={CSS.OptionLabel}>
          Item to render&nbsp;
          <select value={item?.id} onChange={onSelectItem}>
            {selectAllItemIds({ document }).map(({ id, type }) => (
              <option key={id} value={id}>
                {type} - {id}
              </option>
            ))}
          </select>
        </label>
      )}
      <div className={CSS.Row}>
        <div className={CSS.Column}>{!!input && <FusionCanvas input={input} onLayout={onClientSideLayout} onPaint={onClientSidePaint} />}</div>
        <div className={CSS.Column}>
          {input && <FusionCanvas input={{ ...input, forceFallback: { breakCache: true } }} onLayout={onServerSideLayout} onPaint={onServerSidePaint} />}
        </div>
        <div className={CSS.Column}>
          {images.clientSide && images.serverSide && (
            <DiffCanvas img1={images.clientSide} img2={images.serverSide} onDiff={(pixels) => setDiffPixels(pixels)} />
          )}
        </div>
      </div>
      <div className={CSS.Row}>
        <div className={CSS.Column}>
          <pre className={CSS.Pre}>CSR time: {clientSideLayout?.layoutResult?.debugInfo?.timers["total"].toFixed(2)} ms</pre>
          <pre>{formatMeasurementData(clientSideLayout?.layoutResult?.elements[0])}</pre>
        </div>
        <div className={CSS.Column}>
          <pre className={CSS.Pre}>SSR time: {serverSideLayout?.layoutResult?.debugInfo?.timers["total"].toFixed(2)} ms</pre>
          <pre>{formatMeasurementData(serverSideLayout?.layoutResult?.elements[0])}</pre>
        </div>
        <div className={CSS.Column}>
          <pre>Pixel diff: {diffPixels === undefined ? "Loading..." : diffPixels}</pre>
        </div>
      </div>
      {!!document && (
        <>
          <button className={CSS.Button} onClick={() => setShowMore(!showMore)}>
            Show {showMore && "less"} {!showMore && "more"}
          </button>
          {showMore && (
            <div>
              <a className={CSS.Link} href={`https://console.documents.cimpress.io/documentViewer?sourceUrl=${encodeURIComponent(url)}`} target="_blank">
                Open in document viewer
              </a>
              <label className={CSS.Label}>Cimpress document URL</label>
              <input className={CSS.Input} value={url} readOnly />
              {document && (
                <div className={CSS.Row}>
                  <div className={CSS.Column}>
                    <pre className={CSS.Pre}>Document (editable):</pre>
                    <div className={CSS.Editor}>
                      <DocumentEditor document={document} onDocumentChange={onDocumentChange} />
                    </div>
                  </div>
                  {clientSideLayout?.layoutResult && (
                    <div className={CSS.Column}>
                      <pre className={CSS.Pre}>Client side layout result (read-only):</pre>
                      <div className={CSS.Editor}>
                        <JSONReadOnlyViewer key={JSON.stringify(clientSideLayout.layoutResult)} object={clientSideLayout.layoutResult} />
                      </div>
                    </div>
                  )}
                  {clientSideLayout?.layoutError && (
                    <div className={CSS.Column}>
                      <pre className={CSS.Pre}>Client side layout error (read-only):</pre>
                      <div className={CSS.Editor}>{clientSideLayout.layoutError.message}</div>
                    </div>
                  )}
                  {serverSideLayout?.layoutError && (
                    <div className={CSS.Column}>
                      <pre className={CSS.Pre}>Server side layout error:</pre>
                      <div className={CSS.Editor}>{serverSideLayout.layoutError.message}</div>
                    </div>
                  )}
                  {serverSideLayout?.layoutResult && (
                    <div className={CSS.Column}>
                      <pre className={CSS.Pre}>Server side layout result (read-only):</pre>
                      <div className={CSS.Editor}>
                        <JSONReadOnlyViewer key={JSON.stringify(serverSideLayout.layoutResult)} object={serverSideLayout.layoutResult} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
