import { Clippable, ItemClip, TextArea, TextField } from "@mcp-artwork/cimdoc-types-v2";
import { BoundingBox, boundingBoxFromPath } from "../../utils/boundingBox";
import { Matrix } from "../../utils/math/matrix";
import { parsePathData } from "../../utils/parsePathData";
import { parseMM, toMM, toRadians } from "../../utils/unitHelper";
import { ClipPath } from "../Models";
import { buildItemTransform, transformBoundingBox } from "./Transform";
import {
  CalculationRequestCollection,
  CalculationResult,
  CalculationResultCollection,
  ResultFont,
  ResultGlyph,
  TextBlock,
  TextEngine,
} from "@mcp-artwork/rtext";
import { buildInteractiveRequest, interpretTextArea } from "../text/RTextTranslation";
import { initRText } from "../text/initRText";
import { TextCalculationResultCollection } from "@mcp-artwork/rtext/lib-mjs/core/pkg/rtext";
import svgpath from "svgpath";
import * as SvgPath from "svgpath";

/**
 *
 * @param item The clippable item
 * @param parentBounds bounding box of the clippable item if the origin = item, else the item's parent bounds.
 * @param parentTransform transform of the clippable item, contains transforms such as that from rotationAngle.
 * @returns
 */
export async function getClip(item: Clippable, parentBounds: BoundingBox, parentTransform?: Matrix, fontRepositoryUrl?: string): Promise<ClipPath | undefined> {
  if (item.clipping === undefined) {
    return undefined;
  }

  const clipping = item.clipping;
  const position = clipping.position;

  const isRelativeToItem = item.clipping.specification.origin === "item";
  const { svgPath, unit } = await getClipPaths(item.clipping, fontRepositoryUrl);

  const unitScale = toMM(1, unit);
  const boundingBox = boundingBoxFromPath({ path: svgPath });
  const boundingBoxCenter = { x: boundingBox.left + boundingBox.width / 2, y: boundingBox.top + boundingBox.height / 2 };

  const scaleX = position?.scaleX ?? 1;
  const scaleY = position?.scaleY ?? 1;

  const matrix = Matrix.multiply(
    Matrix.multiply(Matrix.translate(-boundingBoxCenter.x, -boundingBoxCenter.y), new Matrix(unitScale * scaleX, 0, 0, unitScale * scaleY, 0, 0)),
    Matrix.translate(boundingBoxCenter.x + parseMM(position?.x ?? "0mm"), boundingBoxCenter.y + parseMM(position?.y ?? "0mm")),
  );

  let transform = Matrix.multiply(
    matrix,
    buildItemTransform({ transforms: clipping.specification.transforms, bounds: transformBoundingBox(boundingBox, matrix), container: parentBounds }),
  );

  // Only apply the clippable item's transforms if this clip is relative to the item
  if (parentTransform && isRelativeToItem) {
    transform = Matrix.multiply(transform, parentTransform);
  }

  // translate by the clippable item's parent bounds' x/y if the origin = panel, since technically the clip is relative to the
  // parent context of the clippable item.
  if (!isRelativeToItem) {
    transform = Matrix.multiply(transform, Matrix.translate(parentBounds.left, parentBounds.top));
  }

  return {
    boundingBox: transformBoundingBox(boundingBox, transform),
    transform,
    path: svgPath.toString(),
    isRelativeToItem: isRelativeToItem,
    usesViewbox: false,
  };
}

export async function getClipWithViewbox(
  clip: ItemClip,
  position: BoundingBox,
  parentTransform: Matrix,
  fontRepositoryUrl?: string,
): Promise<ClipPath | undefined> {
  const { svgPath } = await getClipPaths(clip, fontRepositoryUrl);

  const boundingBox = boundingBoxFromPath({ path: svgPath });

  let transform = Matrix.identity();

  transform = Matrix.multiply(transform, getClipViewboxTransform(position, clip.viewBox as string));
  transform = Matrix.multiply(transform, parentTransform);

  return {
    boundingBox: transformBoundingBox(boundingBox, transform),
    transform,
    path: svgPath.toString(),
    isRelativeToItem: true,
    usesViewbox: true,
  };
}

export function getClipViewboxTransform(position: BoundingBox, viewBox: string): Matrix {
  const viewBoxNum = viewBox.split(" ").map(parseFloat);
  let transform = Matrix.identity();

  transform = Matrix.multiply(transform, Matrix.translate(-viewBoxNum[0], -viewBoxNum[1]));
  transform = Matrix.multiply(transform, Matrix.scale(position.width / viewBoxNum[2], position.height / viewBoxNum[3]));
  transform = Matrix.multiply(transform, Matrix.translate(position.left, position.top));

  return transform;
}

export async function getClipPaths(clip: ItemClip, fontRepositoryUrl?: string): Promise<{ svgPath: Omit<typeof SvgPath, "default">; unit: string }> {
  let unit = "";
  let svgPath: Omit<typeof SvgPath, "default">;

  if (clip.specification.type === "svgPathData") {
    // This should never default to mm unless there is a viewbox defined on the clip, which is currently not a supported feature
    unit = clip.specification.unit ?? "mm";

    [svgPath] = parsePathData({
      pathData: clip.specification.data,
      pixelSize: 1,
      svgPathDataUnit: unit,
      closeBehavior: "always",
      scaleX: 1,
      scaleY: 1,
    });
  } else {
    if (!fontRepositoryUrl) {
      throw Error("A fontRepositoryUrl is required for clipping with text.");
    }
    const textAreaPaths: string = await getTextAreaClipPaths(clip.specification.textArea, fontRepositoryUrl);
    unit = "mm";
    [svgPath] = parsePathData({
      pathData: textAreaPaths,
      pixelSize: 1,
      svgPathDataUnit: unit,
      closeBehavior: "always",
      scaleX: 1,
      scaleY: 1,
    });
  }

  return { svgPath, unit };
}

/**
 * Gets the outlines of the text area. Note this does not support text along a path yet, since there is no need
 * @param textArea
 * @param fontRepositoryUrl
 * @returns The concatenated SVG paths as a single string
 */
async function getTextAreaClipPaths(textArea: TextArea, fontRepositoryUrl: string): Promise<string> {
  // Applying all the functionality around list handling
  const rtext: TextEngine = await initRText(false);

  const { textBlocks } = interpretTextArea(textArea, "print")
    // Remove the bew line characters in case of text along a path, and then remove blocks if empty.
    .reduce<{ listOrdinals: boolean[]; textFields: (TextField & { type?: "inline" | "list" })[]; textBlocks: TextBlock[]; isNewParagraphTextBlock: boolean[] }>(
      (outputItc, itc) => {
        outputItc.isNewParagraphTextBlock.push(itc.isNewParagraphTextBlock);
        outputItc.listOrdinals.push(itc.listOrdinal);
        outputItc.textBlocks.push(itc.textBlock);
        outputItc.textFields.push(itc.textField);
        return outputItc;
      },
      { listOrdinals: [], textFields: [], textBlocks: [], isNewParagraphTextBlock: [] },
    );

  const positionX = parseMM(textArea.position.x);
  const positionY = parseMM(textArea.position.y);

  const rtextRequest: CalculationRequestCollection = buildInteractiveRequest(textArea, undefined, textBlocks, "print", fontRepositoryUrl);
  const rtextResponse: TextCalculationResultCollection = await rtext.process(rtextRequest);
  const resultCollection = rtextResponse.to_obj() as CalculationResultCollection;

  if (!resultCollection.results || resultCollection.results.length !== 1) {
    throw Error("Expected 1 result from rtext");
  }

  const resultFonts: ResultFont[] = resultCollection.fonts as ResultFont[];
  const result: CalculationResult = resultCollection.results[0];

  let totalPath = "";

  for (const glyphRun of result.glyphRuns ?? []) {
    const font: ResultFont = resultFonts[glyphRun.fontIndex ?? 0];
    const { fontSize, baseline } = glyphRun;

    for (const glyph of glyphRun.glyphs) {
      const { xOffset: offsetX, yOffset: offsetY } = glyph;
      const fontScalar: number = fontSize / (font.unitsPerEm as number);
      let transform: Matrix;

      if (glyphRun.orientation === "vertical") {
        // Apply baseline to X instead of Y
        transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX ?? 0) + positionX + baseline, (offsetY ?? 0) + positionY);
      } else {
        transform = new Matrix(fontScalar, 0, 0, -fontScalar, (offsetX ?? 0) + positionX, (offsetY ?? 0) + positionY + baseline);
      }

      transform = Matrix.multiply(Matrix.rotation(toRadians(-glyphRun.glyphPivoting)), transform);

      let glyphPaths: string = (font.glyphs as Record<string, ResultGlyph>)[glyph.index].paths as string;
      glyphPaths = svgpath(glyphPaths).matrix([transform.a, transform.b, transform.c, transform.d, transform.x, transform.y]).toString();

      totalPath += glyphPaths;
    }
  }

  return totalPath;
}
