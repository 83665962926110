import CSS from "./AddDocumentURL.module.css";

type AddDocumentURLProps = {
  onDocumentURLAdded: (url: string) => void;
};

export function AddDocumentURL({ onDocumentURLAdded }: AddDocumentURLProps) {
  return (
    <form
      className={CSS.Form}
      onSubmit={(e) => {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const url = (form.get("cimdocurl") as string) ?? "";
        if (url.startsWith("http")) {
          onDocumentURLAdded(url);
          e.currentTarget.reset();
        } else {
          window.alert("url doesn't start with http");
        }
      }}
    >
      <input className={CSS.Input} placeholder="Document or Preview URL" name="cimdocurl" />
      <button>Load</button>
    </form>
  );
}
